import React, { Component } from 'react';
import { Switch } from 'react-router-dom';

import PublicRoute from '../components/routes/public-route';
import PrivateRoute from '../components/routes/private-route';

import CreatePasswordPage from '../pages/create-password';
import DashboardPage from '../pages/dashboard';
import ForgotPasswordPage from '../pages/forgot-password';
import LoginPage from '../pages/login';

class Routes extends Component {
	render() {
		return (
			<Switch>
				<PublicRoute exact path="/login" component={LoginPage} />
				<PublicRoute exact path="/forgot-password" component={ForgotPasswordPage} />
				<PublicRoute exact path="/create-password" component={CreatePasswordPage} />
				<PrivateRoute path="/" component={DashboardPage} />
			</Switch>
		);
	}
}

export default Routes;
