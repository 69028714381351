import React, { Component } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { Chart } from "react-google-charts";
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Button, Card, CardContent, CircularProgress, Typography,
	Table, TableBody, TableCell, TableContainer, TableRow
} from '@material-ui/core';

import DateRangePicker from '../../../components/inputs/date-range-picker';
import ServerAPI from '../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	title: {
		fontSize: 14,
		width: "100%",
	},
	button: {
		margin: theme.spacing(1)
	},
	flex: {
		display: "flex",
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	fullTable: {
		width: "100%",
		display: "block",
	},
	halfTable: {
		width: "40%",
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			marginBottom: "10px",
		},
	},
	favicon: {
		maxWidth: '20px',
		marginRight: "10px",
		verticalAlign: "middle",
	}
});

class ConversionsPage extends Component {
	state = {
		isLoading: true,
		startTime: new Date((new Date()).setMonth((new Date()).getMonth() - 1)),
		endTime: new Date(),
		conversionsData: {
			chart: [],
			total: 0,
		},
		registrationsData: {
			chart: [],
			total: 0,
		},
	};
	
	updateConversionsData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getConversions(startTime, endTime);
		if (!results.data) {
			return {
				chart: [],
				total: 0,
			};
		}
		
		const conversionsData = [];
		for (const e of results.data.dates) {
			conversionsData.push([
				new Date(e.createdAt),
				this.roundNumber(e.percentage * 100),
				this.roundNumber(e.percentageNewUsers * 100),
				this.roundNumber(e.percentageRecurrentUsers * 100),
			]);
		}
		conversionsData.sort((a, b) =>  a[0] - b[0]);
		
		return {
			chart: conversionsData,
			total: results.data.total,
		};
	};
	
	updateRegistrationsData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getRegistrations(startTime, endTime);
		if (!results.data) {
			return {
				chart: [],
				total: 0,
			};
		}
		
		const registrationsData = [];
		for (const e of results.data.registrations) {
			registrationsData.push([
				new Date(e.createdAt),
				e.experts,
				e.makers,
			]);
		}
		registrationsData.sort((a, b) =>  a[0] - b[0]);
		
		return {
			chart: registrationsData,
			total: results.data.total,
		};
	};
		
	updateData = async (newState = {}) => {
		const conversionsData = await this.updateConversionsData(newState);
		const registrationsData = await this.updateRegistrationsData(newState);
		
		// Check if data changed
		if (deepEqual(this.state.conversionsData, conversionsData)
			&& deepEqual(this.state.registrationsData, registrationsData)) {
			if (!Object.keys(newState).length) return;
			return this.setState({
				...newState,
			});
		}
		
		this.setState({
			...newState,
			isLoading: false,
			conversionsData,
			registrationsData,
		});
	};
	
	roundNumber = (num) => {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();

		this.interval = setInterval(() => {
			this.updateData();
		}, 5000);
	};
	
	componentWillUnmount = () => {
		clearInterval(this.interval);
	};

	setStartTime = (date) => {
		this.updateData({
			startTime: date
		});
	};
	
	setEndTime = (date) => {
		this.updateData({
			endTime: date
		});
	};

	render() {
		const { classes } = this.props
		const {
			isLoading,
			startTime, endTime,
			conversionsData,
			registrationsData,
		} = this.state;
		
		const conversionPerVisitChart = [];
		const conversionPerUserChart = [];
		if (conversionsData.chart) {
			for (const r of conversionsData.chart) {
				conversionPerVisitChart.push([
					r[0],
					r[1]
				]);
				conversionPerUserChart.push([
					r[0],
					r[2],
					r[3]
				]);
			}
		}
		if (!conversionPerVisitChart.length) {
			conversionPerVisitChart.push([
				new Date(),
				0
			]);
		}
		if (!conversionPerUserChart.length) {
			conversionPerUserChart.push([
				new Date(),
				0,
				0
			]);
		}
		
		return (
			<Box className={classes.root}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<div>
						<DateRangePicker
							startDate={startTime}
							endDate={endTime}
							onChangeStart={this.setStartTime}
							onChangeEnd={this.setEndTime}
							/>
						<Card variant="outlined">
							<CardContent>
								<Button variant="contained" color="primary" href="https://www.typeform.com/">
									Go to Typeform
								</Button>
							</CardContent>
						</Card>
						<Card variant="outlined">
							<CardContent>
								<Typography variant="h5" gutterBottom>
									User Conversions
								</Typography>
								<Chart
									width={'100%'}
									height={'100%'}
									chartType="Bar"
									loader={<div>Loading Chart</div>}
									data={[
										['Days', 'Expert Conversions', 'Maker Conversions'],
										...registrationsData.chart,
									]}
									options={{
										title: 'User Conversions',
										hAxis: {
											title: 'Days',
										},
										vAxis: {
											title: 'Number of conversions',
										},
									}}
									/>
							</CardContent>
						</Card>
						<Card variant="outlined">
							<CardContent>
								<Typography variant="h5" gutterBottom>
									Percentage of Conversions per Pageview
								</Typography>
								<Chart
									width={'100%'}
									height={'100%'}
									chartType="Bar"
									loader={<div>Loading Chart</div>}
									data={[
										['Days', 'Conversion per Visit'],
										...conversionPerVisitChart
									]}
									options={{
										title: 'Conversions per Visits',
										hAxis: {
											title: 'Days',
										},
										vAxis: {
											title: 'Percentage',
											minValue: 0,
											maxValue: 100,
										},
									}}
									/>
							</CardContent>
						</Card>
						<Card variant="outlined">
							<CardContent>
								<Typography variant="h5" gutterBottom>
									Percentage of Conversions per User
								</Typography>
								<Chart
									width={'100%'}
									height={'100%'}
									chartType="Bar"
									loader={<div>Loading Chart</div>}
									data={[
										['Days', 'Conversion per New User', 'Conversion per Recurrent User'],
										...conversionPerUserChart,
									]}
									options={{
										title: 'Conversions per Users',
										hAxis: {
											title: 'Days',
										},
										vAxis: {
											title: 'Percentage',
											minValue: 0,
											maxValue: 100,
										},
									}}
									/>
							</CardContent>
						</Card>
						<Card variant="outlined">
							<CardContent>
								<TableContainer>
									<Table aria-label="simple table">
										<TableBody>
											<TableRow>
												<TableCell>Total Conversions </TableCell>
												<TableCell>
													{conversionsData.total.conversions}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Expert Conversions</TableCell>
												<TableCell>
													{registrationsData.total.experts}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Maker Conversions</TableCell>
												<TableCell>
													{registrationsData.total.makers}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Conversions per Visit</TableCell>
												<TableCell>
													{this.roundNumber(conversionsData.total.percentage * 100) + "%"}
												</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Conversions per Recurrent User</TableCell>
												<TableCell>
													{this.roundNumber(conversionsData.total.percentageRecurrentUsers * 100) + "%"}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>
					</div>
				)}
			</Box>
		);
	}
}

ConversionsPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

ConversionsPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(ConversionsPage);
