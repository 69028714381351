const getUrls = (url) => {
	let isGoogleDrive = false;
	let previsualizeUrl = "https://drive.google.com/viewer?embedded=true&url=" + encodeURIComponent(url);
	let downloadUrl = url;
	let displayUrl = url;

	if (url.indexOf('google.com') > -1) {
		const matches = url.match(/\/d\/(.*)\//);
		if (matches && matches.length >= 2)  {
			const id = matches[1];
			isGoogleDrive = true;
			previsualizeUrl = "https://drive.google.com/file/d/" + id + "/preview";
			downloadUrl = "https://drive.google.com/uc?export=download&id=" + id;
			displayUrl = "https://drive.google.com/uc?id=" + id;
		}
	}
	
	return {
		isGoogleDrive,
		previsualizeUrl,
		downloadUrl,
		displayUrl,
	};
};

async function toBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = () => resolve(reader.result);
		reader.onerror = error => reject(error);
		reader.readAsDataURL(file);
	});
}

const fileUtils = {
	getUrls,
	toBase64,
};

export default fileUtils;
