import React, { Component } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { Chart } from "react-google-charts";
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Card, CardContent, CircularProgress, Typography,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';

import DateRangePicker from '../../../components/inputs/date-range-picker';
import ServerAPI from '../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	title: {
		fontSize: 14,
		width: "100%",
	},
	button: {
		margin: theme.spacing(1)
	},
	flex: {
		display: "flex",
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	fullTable: {
		width: "100%",
		display: "block",
	},
	halfTable: {
		width: "40%",
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			marginBottom: "10px",
		},
	},
	favicon: {
		maxWidth: '20px',
		marginRight: "10px",
		verticalAlign: "middle",
	}
});

class TimePage extends Component {
	state = {
		isLoading: true,
		startTime: new Date((new Date()).setMonth((new Date()).getMonth() - 1)),
		endTime: new Date(),
		avgSessionTimeDate: [],
		pageDurationData: [],
	};

	updateAvgSessionTime = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getAvgSessionTime(startTime, endTime);
		if (!results.data) return [];

		const avgSessionTime = [];
		for (const e of results.data.events) {
			avgSessionTime.push([
				new Date(e.createdAt),
				e.avgDailyLength
			]);
		}
		avgSessionTime.sort((a, b) =>  a[0] - b[0]);

		return avgSessionTime;
	};

	updatePageDurationData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getVisitsByPage(startTime, endTime);
		if (!results.data) return [];

		// Hardcode order based on visists
		const pageDurationData = results.data.total;
		pageDurationData.sort((a, b) => b.visits - a.visits || a.page.localeCompare(b.page));

		return pageDurationData;
	};

	updateAvgPercentageDuration = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getPercentageDurationByPage(startTime, endTime);
		if (!results.data) return {};

		const avgPercentageDurationDate = {};
		for (const p of results.data.total) {
			avgPercentageDurationDate[p.page] = this.roundNumber(p.avgPercentage * 100);
		}

		return avgPercentageDurationDate;
	};

	updateData = async (newState = {}) => {
		const avgSessionTimeDate = await this.updateAvgSessionTime(newState);
		const pageDurationData = await this.updatePageDurationData(newState);
		const avgPercentageDurationDate = await this.updateAvgPercentageDuration(newState);

		// Check if data changed
		if (deepEqual(this.state.avgSessionTimeDate, avgSessionTimeDate)
			&& deepEqual(this.state.pageDurationData, pageDurationData)) {
			if (!Object.keys(newState).length) return;
			return this.setState({
				...newState,
			});
		}

		// Merge data into one row
		for (let i = 0; i < pageDurationData.length; i++) {
			const p = pageDurationData[i];
			pageDurationData[i] = {
				...p,
				avgPercentage: avgPercentageDurationDate[p.page],
			};
		}

		this.setState({
			...newState,
			isLoading: false,
			avgSessionTimeDate,
			pageDurationData,
		});
	}

	roundNumber = (num) => {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();

		this.interval = setInterval(() => {
			this.updateData();
		}, 5000);
	};

	componentWillUnmount = () => {
		clearInterval(this.interval);
	};

	setStartTime = (date) => {
		this.updateData({
			startTime: date,
		});
	};

	setEndTime = (date) => {
		this.updateData({
			endTime: date,
		});
	};

	render() {
		const { classes } = this.props
		const {
			isLoading,
			startTime, endTime,
			avgSessionTimeDate,
			pageDurationData
		} = this.state;

		return (
			<Box className={classes.root}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<div>
						<DateRangePicker
							startDate={startTime}
							endDate={endTime}
							onChangeStart={this.setStartTime}
							onChangeEnd={this.setEndTime}
							/>
						<Card variant="outlined">
							<CardContent>
								<Typography variant="h5" gutterBottom>
									Average Session Time
								</Typography>
								<Chart
									width={'100%'}
									height={'100%'}
									chartType="Bar"
									loader={<div>Loading Chart</div>}
									data={[
										['Day', 'Avg Session Time'],
										...avgSessionTimeDate,
									]}
									options={{
										title: 'Average Session Time',
										chartArea: { width: '50%' },
										hAxis: {
											title: 'Days',
										},
										vAxis: {
											title: 'Seconds',
										},
									}}
									/>
							</CardContent>
						</Card>
						<Card variant="outlined">
							<CardContent>
								<Typography variant="h5" gutterBottom>
									Time per Page
								</Typography>
								<TableContainer>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Pages</TableCell>
												<TableCell>Views</TableCell>
												<TableCell>Average Duartion</TableCell>
												<TableCell>Percentage of Session</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{pageDurationData.map((row) => {
												const date = new Date(0);
												date.setSeconds(Math.round(row.avgLength));
												const timeString = date.toISOString().substr(11, 8);
												return (
													<TableRow key={row.page}>
														<TableCell>{row.page}</TableCell>
														<TableCell>{row.visits}</TableCell>
														<TableCell>{timeString}</TableCell>
														<TableCell>{row.avgPercentage + "%"}</TableCell>
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>
					</div>
				)}
			</Box>
		);
	}
}

TimePage.propTypes = {
	classes: PropTypes.object.isRequired,
};

TimePage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(TimePage);
