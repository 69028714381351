import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import {
	AppBar, BottomNavigation, CssBaseline, Drawer,
	Hidden, IconButton, Toolbar, Typography,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';

// Analytics
import PageviewsPage from './analytics/pageviews';
import UsersPage from './analytics/users';
import TimePage from './analytics/time';
import BouncesPage from './analytics/bounces';
import ConversionsPage from './analytics/conversions';

import NotFoundPage from '../errors/not-found';

import Config from '../../config';
import AppDrawer from '../../components/app-drawer';

const drawerWidth = 240;

const styles = theme => ({
	root: {
		display: 'flex',
	},
	appBar: {
		//width: `calc(100% - ${drawerWidth}px)`,
	    //marginLeft: drawerWidth,
		zIndex: theme.zIndex.drawer + 1,
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	title: {
		flexGrow: 1,
		fontSize: "1.2rem",
	},
	logoImage: {
		marginRight: '15px',
	},
	toolbar: {
		...theme.mixins.toolbar,
		[theme.breakpoints.up('xs')]: {
			minHeight: '48px',
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: '55px',
		},
		minHeight: '55px',
	},
	toolbarSpace: {
		...theme.mixins.toolbar,
		[theme.breakpoints.up('xs')]: {
			minHeight: '55px',
		},
		[theme.breakpoints.up('sm')]: {
			minHeight: '55px',
		},
		minHeight: '55px',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		width: "100%",
		flexGrow: 1,
		padding: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0),
		},
	},
	devIcon: {
		"& img": {
			width: "50px",
			height: "50px",
			padding: "10px",
		},
	},
	envBanner: {
		textAlign: "center",
		background: "#008800",
	},
	bannerSpacing: {
		height: "20px",
	},
});

class DashboardPage extends Component {
	state = {
		mobileOpen: false,
	};

	handleDrawerToggle = () => {
		const { mobileOpen } = this.state;
		this.setState({ mobileOpen: !mobileOpen });
	};

	render() {
		const { classes } = this.props;
		const { mobileOpen } = this.state;

		const isDev = Config.isDev()

		return (
			<div className={classes.root}>
				<CssBaseline />
				<AppBar position="fixed" className={classes.appBar}>
					{isDev &&
						<div className={classes.envBanner}>This environment is {Config.environment}</div>
					}
					<Toolbar className={classes.toolbar}>
						<IconButton
							className={classes.menuButton}
							color="inherit"
							aria-label="Open drawer"
							edge="start"
							onClick={this.handleDrawerToggle}
							>
							<Menu />
						</IconButton>
						<img
							className={classes.logoImage}
							src="/assets/images/logos/lastbasic-icon.svg"
							alt="LB Logo"
							height="25"
							width="25"
							/>
						<Typography variant="h6" className={classes.title}>
							Analytics
						</Typography>
					</Toolbar>
				</AppBar>
				<Hidden smUp implementation="css">
					<Drawer
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper,
						}}
						anchor="left"
						open={mobileOpen}
						onClose={this.handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
						>
						{isDev &&
							<div className={classes.bannerSpacing}></div>
						}
						<div className={classes.toolbarSpace} />
						<AppDrawer />
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						className={classes.drawer}
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						anchor="left"
						>
						{isDev &&
							<div className={classes.bannerSpacing}></div>
						}
						<div className={classes.toolbarSpace} />
						<AppDrawer />
					</Drawer>
				</Hidden>
				<div className={classes.content}>
					{isDev &&
						<div className={classes.bannerSpacing}></div>
					}
					<div className={classes.toolbarSpace} />
					<Switch>
						<Route exact path="/" component={PageviewsPage} />
						<Route exact path="/pageviews" component={PageviewsPage} />
						<Route exact path="/users" component={UsersPage} />
						<Route exact path="/time" component={TimePage} />
						<Route exact path="/bounces" component={BouncesPage} />
						<Route exact path="/conversions" component={ConversionsPage} />
						<Route path="/" component={NotFoundPage} />
					</Switch>
				</div>
				<BottomNavigation />
			</div>
		);
	}
}

DashboardPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

DashboardPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(DashboardPage);
