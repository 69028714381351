import React from 'react';
import { Route } from 'react-router-dom';

import AbstractRoute from './abstract-route';

class PublicRoute extends React.Component {
	render() {
		const { component: Component, ...rest } = this.props;
		
		return (
			<Route
				{...rest}
				render={(routeProps) => <AbstractRoute
					{...routeProps}
					component={Component}
					isPrivate={false}
					redirectTo={"/"}
					/>}
				/>
		);
	}
}

export default PublicRoute;
