import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import {
	applyMiddleware, combineReducers, createStore,
} from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { SnackbarProvider } from 'notistack';
import { createMuiTheme, darken, ThemeProvider } from '@material-ui/core/styles';
import { blue, pink } from '@material-ui/core/colors';

import Config from './config';
import Routes from './routes';
import rootReducer from './reducers';
import { HistoryUtils } from './utils';

import './index.css';

const paletteType = 'light';
const customTheme = createMuiTheme({
	/*shadows: ["none"],
	mixins: {
		denseToolbar: {
		  minHeight: 48
		}
	},*/
	palette: {
		primary: {
			main: paletteType === 'light' ? blue[700] : blue[200],
		},
		secondary: {
			main: paletteType === 'light' ? darken(pink.A400, 0.1) : pink[200],
		},
		type: paletteType,
	},
});

const history = createBrowserHistory({ basename: Config.basePath });
const store = createStore(combineReducers(rootReducer), applyMiddleware(thunk));

HistoryUtils.initHistoryUtils(history);

ReactDOM.render(
	<Provider store={store}>
		<SnackbarProvider maxSnack={3}>
			<ThemeProvider theme={customTheme}>
				<Router history={history}>
					<Routes />
				</Router>
			</ThemeProvider>
		</SnackbarProvider>
	</Provider>,
	document.getElementById('root')
);
