import React, { Component } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Card, CardContent, CircularProgress, Typography,
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';

import DateRangePicker from '../../../components/inputs/date-range-picker';
import ServerAPI from '../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	title: {
		fontSize: 14,
		width: "100%",
	},
	datePicker: {
		display: "flex",
		justifyContent: "center",
		padding: "20px 0px",
	},
	button: {
		margin: theme.spacing(1)
	},
	flex: {
		display: "flex",
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	fullTable: {
		width: "100%",
		display: "block",
	},
	halfTable: {
		width: "40%",
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			marginBottom: "10px",
		},
	},
	favicon: {
		maxWidth: '20px',
		marginRight: "10px",
		verticalAlign: "middle",
	}
});

class UsersPage extends Component {
	state = {
		isLoading: true,
		startTime: new Date((new Date()).setMonth((new Date()).getMonth() - 1)),
		endTime: new Date(),
		uniqueUsersData: {},
		recurrentUsersData: {},
	};

	updateUniqueUsersData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getNewUsersCount(startTime, endTime);
		if (!results.data) return {};
		
		return results.data;
	};
	
	updateRecurrentUsersData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getRecurrentUsersCount(startTime, endTime);
		if (!results.data) return {};
		
		return results.data;
	};

	updateData = async (newState = {}) => {
		const uniqueUsersData = await this.updateUniqueUsersData(newState);
		const recurrentUsersData = await this.updateRecurrentUsersData(newState);
		
		// Check if data changed
		if (deepEqual(this.state.uniqueUsersData, uniqueUsersData)
			&& deepEqual(this.state.recurrentUsersData, recurrentUsersData)) {
			if (!Object.keys(newState).length) return;
			return this.setState({
				...newState,
			});
		}
		
		this.setState({
			...newState,
			isLoading: false,
			uniqueUsersData,
			recurrentUsersData,
		});
	};
	
	roundNumber = (num) => {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();

		this.interval = setInterval(() => {
			this.updateData();
		}, 5000);
	};
	
	componentWillUnmount = () => {
		clearInterval(this.interval);
	};

	setStartTime = (date) => {
		this.updateData({
			startTime: date,
		});
	};
	
	setEndTime = (date) => {
		this.updateData({
			endTime: date,
		});
	};

	render() {
		const { classes } = this.props
		const {
			isLoading,
			startTime, endTime,
			uniqueUsersData,
			recurrentUsersData,
		} = this.state;
		
		return (
			<Box className={classes.root}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<div>
						<DateRangePicker
							startDate={startTime}
							endDate={endTime}
							onChangeStart={this.setStartTime}
							onChangeEnd={this.setEndTime}
							/>
						<Card variant="outlined">
							<CardContent>
								<Typography variant="h5" gutterBottom>
									Users
								</Typography>
								<TableContainer>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Type</TableCell>
												<TableCell>Percentage</TableCell>
												<TableCell>Quantity</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<TableRow>
												<TableCell>Unique Users</TableCell>
												<TableCell>
													{this.roundNumber(uniqueUsersData.percentage * 100) + "%"}
												</TableCell>
												<TableCell>{uniqueUsersData.totalUniqueVisits}</TableCell>
											</TableRow>
											<TableRow>
												<TableCell>Recurrent Users</TableCell>
												<TableCell>
													{this.roundNumber(recurrentUsersData.percentage * 100) + "%"}
												</TableCell>
												<TableCell>{recurrentUsersData.totalRecurringVisits}</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>
					</div>
				)}
			</Box>
		);
	}
}

UsersPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

UsersPage.defaultProps = {
	classes: {}
};

export default withStyles(styles)(UsersPage);
