import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import qs from 'qs';
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Button, Card, CardContent, Grid,
	TextField, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import ServerAPI from '../services/server-api';

const styles = theme => ({
	root: {
		background: "#f7f7f7",
	},
	parentGrid: {
		minHeight: '100vh',
	},
	cardContent: {
		padding: '20px',
		minWidth: '300px',
		[theme.breakpoints.down('sm')]: {
			boxSizing: "border-box",
		},
	},
	inputText: {
		marginBottom: '20px',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
	},
	submitButton: {
		marginBottom: '20px',
		maxWidth: '100%',
	},
});

class CreatePasswordPage extends Component {
	state = {
		password: '',
		repeatPassword: '',
		alert: null,
	};

	onChangePassword = (event) => {
		this.setState({ password: event.target.value, alert: null });
	};
	
	onChangeRepeatPassword = (event) => {
		this.setState({ repeatPassword: event.target.value, alert: null });
	};

	onSubmit = async (e) => {
		e.preventDefault();
		const { history } = this.props;
		const { password, repeatPassword } = this.state;
	
		if (password !== repeatPassword) {
			return this.setState({
				alert: {
					type: 'error',
					message: 'Passwords must be the same'
				}
			});
		}
		if (password.length <= 5) {
			return this.setState({
				alert: {
					type: 'error',
					message: 'Password must be at least 6 chars'
				}
			});
		}
	
		const { token } = this.getParams();
		if (!token) return false;
	
		const result = await ServerAPI.createPassword({ token, password });
		if (result.error) {
			return this.setState({
				alert: {
					type: 'error',
					message: result.error.message || 'Could not recover password'
				}
			});
		}
		
		history.push({
			pathname: '/login',
			search: '?passwordCreated=true',
		});
	};

	getParams() {
		return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
	}

	render() {
		const { classes } = this.props;
		const { password, repeatPassword, alert } = this.state;
		
		// Check we have token in query
		const { token } = this.getParams();
		if (!token) return <Redirect to="/" />;
		
		return (
			<Box className={classes.root}>
				<Grid
					className={classes.parentGrid}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justify="center"
					>
					<Grid item>
						<Card>
							<CardContent className={classes.cardContent}>
								<Typography variant="h5" gutterBottom>
									Create new password
								</Typography>
								<form className={classes.form} onSubmit={this.onSubmit}>
									<TextField
										className={classes.inputText}
										label="Password"
										type="password"
										value={password}
										onChange={this.onChangePassword}
										/>
									<TextField
										className={classes.inputText}
										label="Repeat password"
										type="password"
										value={repeatPassword}
										onChange={this.onChangeRepeatPassword}
										/>
									<Button
										className={classes.submitButton}
										variant="contained"
										color="primary"
										type="submit">
										Submit
									</Button>
								</form>
								{alert && (
									<Alert severity={alert.type} className={classes.alert}>{alert.message}</Alert>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

CreatePasswordPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

CreatePasswordPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(CreatePasswordPage);
