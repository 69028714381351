import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { compose } from 'redux';

import { withStyles } from '@material-ui/core/styles';
import {
	Divider, List, ListItem, ListItemText, ListItemIcon,
} from '@material-ui/core';
import {
	CallMissed, ExitToApp, HowToReg, Timelapse, Pageview, People,
} from '@material-ui/icons';

import * as actions from '../reducers/user/actions';

const styles = theme => ({
	listItem: {
		paddingTop: "6px",
		paddingBottom: "6px",
	},
	listItemText: {
		fontSize: "0.9rem",
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
});

class AppDrawer extends Component {
	onClickLogout = () => {
		const { logout } = this.props;
		return logout();
	};
	
	render() {
		const { classes } = this.props;

		return (
			<List>
				<ListItem button component={Link} to="/pageviews"
					className={`${classes.listItem}`}>
					<ListItemIcon>
						<Pageview />
					</ListItemIcon>
					<ListItemText classes={{primary: classes.listItemText}} primary={'Pageviews'} />
				</ListItem>
				<ListItem button component={Link} to="/users"
					className={`${classes.listItem}`}>
					<ListItemIcon>
						<People />
					</ListItemIcon>
					<ListItemText classes={{primary: classes.listItemText}} primary={'Users'} />
				</ListItem>
				<ListItem button component={Link} to="/time"
					className={`${classes.listItem}`}>
					<ListItemIcon>
						<Timelapse />
					</ListItemIcon>
					<ListItemText classes={{primary: classes.listItemText}} primary={'Time'} />
				</ListItem>
				<ListItem button component={Link} to="/bounces"
					className={`${classes.listItem}`}>
					<ListItemIcon>
						<CallMissed />
					</ListItemIcon>
					<ListItemText classes={{primary:classes.listItemText}} primary={'Bounces'} />
				</ListItem>
				<ListItem button component={Link} to="/conversions"
					className={`${classes.listItem}`}>
					<ListItemIcon>
						<HowToReg />
					</ListItemIcon>
					<ListItemText classes={{primary: classes.listItemText}} primary={'Conversions'} />
				</ListItem>
				<Divider />
				<ListItem button onClick={this.onClickLogout} className={classes.listItem}>
					<ListItemIcon>
						<ExitToApp />
					</ListItemIcon>
					<ListItemText classes={{primary: classes.listItemText}} primary={'Logout'} />
				</ListItem>
			</List>
		);
	}
}

AppDrawer.propTypes = {
	classes: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
};

AppDrawer.defaultProps = {
	classes: {},
	logout() {},
};

const mapDispatchToProps = dispatch => ({
	logout: () => dispatch(actions.logout()),
});

export default compose(
	connect(
		null,
		mapDispatchToProps,
	),
	withStyles(styles)
)(AppDrawer);
