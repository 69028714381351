import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { default as ReactDatePicker } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { withStyles } from '@material-ui/core/styles';
import { Button, Card, CardContent } from '@material-ui/core';

const styles = theme => ({
	root: {
		textAlign: "center",
	},
	cardContent: {
		padding: theme.spacing(0),
	},
	datePicker: {
		display: "flex",
		justifyContent: "center",
		padding: "20px 0px",
	},
	clearableInput: {
		paddingRight: "27px",
	},
	startDate: {
		borderRight: "none",
		borderRadius: "6px 0px 0px 6px",
	},
	endDate: {
		borderRadius: "0px 6px 6px 0px",
	},
});

class DateRangePicker extends Component {
	shouldComponentUpdate(nextProps) {
		const { startDate: prevStartDate, endDate: prevEndDate } = this.props;
		const { startDate, endDate } = nextProps;
		
		return (startDate !== prevStartDate) || (endDate !== prevEndDate);
	}
	
	render() {
		const {
			classes,
			className,
			container,
			isClearable,
			placeholderText,
			startDate, endDate,
			onChangeStart, onChangeEnd,
		} = this.props;
		
		const CustomInput = ({ value, onClick, type }) => (
			<Button
				className={
					((isClearable && value) ? classes.clearableInput : '')
					+ " "
					+ ((type === 'start_date') ? classes.startDate : classes.endDate)
				}
				variant="outlined"
				onClick={onClick}>
				{value || placeholderText}
			</Button>
		);

		const MyCustomInput = React.forwardRef((props, ref) => (
			<CustomInput innerRef={ref} {...props} />
		));
		
		const component = (
			<div className={classes.datePicker + " " + className}>
				<ReactDatePicker
					isClearable={isClearable}
					selected={startDate}
					onChange={date => onChangeStart(date)}
					selectsStart
					startDate={startDate}
					endDate={endDate}
					maxDate={endDate}
					customInput={<MyCustomInput type="start_date" />}
					dateFormat="d/MM/yyyy"
					popperPlacement="bottom-start"
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					popperModifiers={{
						flip: {
							behavior: ['bottom-end', 'bottom-start']
						}
					}}
					/>
				<ReactDatePicker
					isClearable={isClearable}
					selected={endDate}
					onChange={date => onChangeEnd(date)}
					selectsEnd
					startDate={startDate}
					endDate={endDate}
					minDate={startDate}
					customInput={<MyCustomInput type="end_date" />}
					dateFormat="d/MM/yyyy"
					popperPlacement="bottom-start"
					showMonthDropdown
					showYearDropdown
					dropdownMode="select"
					popperModifiers={{
						flip: {
							behavior: ['bottom-end', 'bottom-start']
						}
					}}
					/>
			</div>
		);
		
		return (container) ? (
			<Card className={classes.root} variant="outlined">
				<CardContent className={classes.cardContent}>
					{component}
				</CardContent>
			</Card>
		) : component;
	}
}

DateRangePicker.propTypes = {
	className: PropTypes.string,
	container: PropTypes.bool,
	isClearable: PropTypes.bool,
	placeholderText: PropTypes.string,
	startDate: PropTypes.instanceOf(Date),
	endDate: PropTypes.instanceOf(Date),
	onChangeStart: PropTypes.func,
	onChangeEnd: PropTypes.func,
};

DateRangePicker.defaultProps = {
	className: '',
	container: true,
	isClearable: false,
	placeholderText: '',
	startDate: new Date(),
	endDate: new Date(),
	onChangeStart() {},
	onChangeEnd() {},
};

export default withStyles(styles)(DateRangePicker);
