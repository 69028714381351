import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import {
	Box, Button, Card, CardContent, Grid, TextField, Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import ServerAPI from '../services/server-api';

const styles = theme => ({
	root: {
		background: "#f7f7f7",
	},
	parentGrid: {
		minHeight: '100vh',
	},
	cardContent: {
		padding: '20px',
		minWidth: '300px',
		[theme.breakpoints.down('sm')]: {
			boxSizing: "border-box",
		},
	},
	inputText: {
		marginBottom: '20px',
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
	},
	submitButton: {
		marginBottom: '20px',
		maxWidth: '100%',
	},
	additionalLinks: {
		textAlign: 'center',
		color: theme.palette.primary.main,
	},
	alert: {
		marginTop: '20px',
	},
});

class ForgotPasswordPage extends Component {
	state = {
		email: '',
		alert: null,
	};

	onChangeEmail = (event) => {
		this.setState({ email: event.target.value, alert: null });
	};

	onSubmit = async (e) => {
		e.preventDefault();
		const { email } = this.state;
	
		const result = await ServerAPI.forgotPassword({ email });
		if (result.error) {
			return this.setState({
				alert: {
					type: 'error',
					message: result.error.message || 'Could not recover password'
				}
			});
		}
		
		return this.setState({
			alert: {
				type: 'success',
				message: 'We have sent a link to your email',
			}
		});
	};

	render() {
		const { classes } = this.props;
		const { email, alert } = this.state;
		
		return (
			<Box className={classes.root}>
				<Grid
					className={classes.parentGrid}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justify="center"
					>
					<Grid item>
						<Card>
							<CardContent className={classes.cardContent}>
								<Typography variant="h5" gutterBottom>
									Forgot password?
								</Typography>
								<form className={classes.form} onSubmit={this.onSubmit}>
									<TextField
										className={classes.inputText}
										label="Email"
										value={email}
										onChange={this.onChangeEmail}
										/>
									<Button
										className={classes.submitButton}
										variant="contained"
										color="primary"
										type="submit">
										Submit
									</Button>
									<Link
										className={classes.additionalLinks}
										to="/login">
										Go back to login
									</Link>
								</form>
								{alert && (
									<Alert severity={alert.type} className={classes.alert}>{alert.message}</Alert>
								)}
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</Box>
		);
	}
}

ForgotPasswordPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

ForgotPasswordPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(ForgotPasswordPage);
