import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

import qs from 'qs';
import { withStyles } from '@material-ui/core/styles';
import {
	Button, Card, CardContent, Checkbox, FormControlLabel, Grid, TextField, Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import * as actions from '../reducers/user/actions';

const styles = theme => ({
	root: {
		background: "#f7f7f7",
	},
	cardContent: {
		padding: '20px',
		minWidth: '300px',
		[theme.breakpoints.down('sm')]: {
			boxSizing: "border-box",
		},
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
	},
	inputText: {
		marginBottom: "10px",
	},
	submitButton: {
		marginTop: '20px',
		marginBottom: '20px',
	},
	alert: {
		margin: '20px 0px',
	},
	parentGrid: {
		minHeight: '100vh',
	},
	additionalLinks: {
		textAlign: 'center',
		color: theme.palette.primary.main,
	},
});

class LoginPage extends Component {
	state = {
		username: '',
		password: '',
		rememberMe: true,
	};

	onChangeUsername = (event) => {
		this.setState({ username: event.target.value });
	};

	onChangePassword = (event) => {
		this.setState({ password: event.target.value });
	};

	onChangeRememberMe = (event) => {
		this.setState({ rememberMe: event.target.checked });
	};

	onSubmit = async (e) => {
		e.preventDefault();
		const { login } = this.props;
		const { username, password, rememberMe } = this.state;

		return login({ username, password, rememberMe });
	};

	getParams() {
		return qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
	}

	render() {
		const { classes, user } = this.props
		const { username, password, rememberMe } = this.state;
		const { passwordCreated } = this.getParams();
		
		return (
			<div className={classes.root}>
				<Grid
					className={classes.parentGrid}
					container
					spacing={0}
					direction="column"
					alignItems="center"
					justify="center"
					>
					<Grid item>
						<Card>
							<CardContent className={classes.cardContent}>
								<Typography variant="h5" gutterBottom>
									Login
								</Typography>
								{passwordCreated && (
									<Alert severity="success" className={classes.alert}>
										Password changed. Go ahead and login
									</Alert>
								)}
								{user.error && (
									<Alert severity="error" className={classes.alert}>
										{user.error}
									</Alert>
								)}
								<form className={classes.form} onSubmit={this.onSubmit}>
									<TextField
										id="standard-basic"
										className={classes.inputText}
										label="Email"
										value={username}
										onChange={this.onChangeUsername}
										/>
									<TextField
										id="standard-password-input"
										className={classes.inputText}
										label="Password"
										type="password"
										value={password}
										autoComplete="current-password"
										onChange={this.onChangePassword}
										/>
									<FormControlLabel
										control={
											<Checkbox
												checked={rememberMe}
												onChange={this.onChangeRememberMe}
												name="rememberMe"
												color="primary"
												/>
										}
										label="Remember me"
										/>
									<Button
										className={classes.submitButton}
										variant="contained"
										color="primary"
										type="submit">
										Login
									</Button>
									<Link
										className={classes.additionalLinks}
										to="/forgot-password">
										Forgot Password?
									</Link>
								</form>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			</div>
		);
	}
}

LoginPage.propTypes = {
	classes: PropTypes.shape({}).isRequired,
	login: PropTypes.func.isRequired,
	user: PropTypes.shape({
		id: PropTypes.string,
	}),
};

LoginPage.defaultProps = {
	user: {},
};

const mapStateToProps = ({ user }) => ({
	user,
});

const mapDispatchToProps = dispatch => ({
	login: data => dispatch(actions.login(data)),
});

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
)(LoginPage);
