import React, { Component } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { Chart } from "react-google-charts";
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Card, CardContent, CircularProgress, Typography,
	Table, TableBody, TableCell, TableContainer, TableRow
} from '@material-ui/core';

import DateRangePicker from '../../../components/inputs/date-range-picker';
import ServerAPI from '../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	title: {
		fontSize: 14,
		width: "100%",
	},
	button: {
		margin: theme.spacing(1)
	},
	flex: {
		display: "flex",
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	fullTable: {
		width: "100%",
		display: "block",
	},
	halfTable: {
		width: "40%",
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			marginBottom: "10px",
		},
	},
	favicon: {
		maxWidth: '20px',
		marginRight: "10px",
		verticalAlign: "middle",
	}
})

class BouncesPage extends Component {
	state = {
		isLoading: true,
		startTime: new Date((new Date()).setMonth((new Date()).getMonth() - 1)),
		endTime: new Date(),
		bounceRateData: {
			chart: [],
			percentage: 0,
		},
	};
	
	updateBounceRateData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getBounceRate(startTime, endTime);
		if (!results.data) {
			return {
				chart: [],
				percentage: 0,
			};
		}
		
		const bounceRateData = [];
		for (const e of results.data.bounces) {
			bounceRateData.push([
				new Date(e.createdAt),
				this.roundNumber(e.percentage * 100),
			]);
		}
		bounceRateData.sort((a, b) =>  a[0] - b[0]);
		
		return {
			chart: bounceRateData,
			percentage: results.data.percentage,
		};
	};
		
	updateData = async (newState = {}) => {
		const bounceRateData = await this.updateBounceRateData(newState);
		
		// Check if data changed
		if (deepEqual(this.state.bounceRateData, bounceRateData)) {
			if (!Object.keys(newState).length) return;
			return this.setState({
				...newState,
			});
		}
		
		this.setState({
			...newState,
			isLoading: false,
			bounceRateData,
		});
	};
	
	roundNumber = (num) => {
		return Math.round((num + Number.EPSILON) * 100) / 100;
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();

		this.interval = setInterval(() => {
			this.updateData();
		}, 5000);
	};
	
	componentWillUnmount = () => {
		clearInterval(this.interval);
	};

	setStartTime = (date) => {
		this.updateData({
			startTime: date
		});
	};
	
	setEndTime = (date) => {
		this.updateData({
			endTime: date
		});
	};

	render() {
		const { classes } = this.props
		const {
			isLoading,
			startTime, endTime,
			bounceRateData,
		} = this.state;
		
		return (
			<Box className={classes.root}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<div>
						<DateRangePicker
							startDate={startTime}
							endDate={endTime}
							onChangeStart={this.setStartTime}
							onChangeEnd={this.setEndTime}
							/>
						<Card variant="outlined">
							<CardContent>
								<Typography variant="h5" gutterBottom>
									Bounce Rate per Day
								</Typography>
								<Chart
									width={'100%'}
									height={'100%'}
									chartType="Bar"
									loader={<div>Loading Chart</div>}
									data={[
										['Days', 'Bounce Rate'],
										...bounceRateData.chart,
									]}
									options={{
										title: 'Bounce Rate per Day',
										hAxis: {
											title: 'Days',
										},
										vAxis: {
											title: 'Bounce Rate',
											minValue: 0,
											maxValue: 100,
										},
									}}
									/>
							</CardContent>
						</Card>
						<Card variant="outlined">
							<CardContent>
								<TableContainer>
									<Table aria-label="simple table">
										<TableBody>
											<TableRow>
												<TableCell>Total Average Bounce Rate</TableCell>
												<TableCell>
													{this.roundNumber(bounceRateData.percentage * 100) + "%"}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</Card>
					</div>
				)}
			</Box>
		);
	}
}

BouncesPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

BouncesPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(BouncesPage);
