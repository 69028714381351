import fetch from 'isomorphic-fetch';

import Config from '../config';
import { auth, ObjectUtils } from '../utils';

class ServerAPI {
	static async forceLogout() {
		auth.removeTokens();
		window.location.reload();
	}
	
	static _filterRequest(obj) {
		return Object.fromEntries(Object.entries(obj).filter(e => {
			const value = e[1];
			return (typeof value !== 'undefined');
		}).map(e => {
			let [name, value] = e;
			if (value instanceof Date) value = value.getTime();
			if (ObjectUtils.isPlainObject(value)) value = this._filterRequest(value);
			return [name, value];
		}));
	}
	
	static async _request(method, path, params, body, opts = {}) {
		opts.repeat = !!opts.repeat;
		opts.public = !!opts.public;
		
		if (!path) path = '/';
		if (path.substring(0, 1) !== '/') path = '/' + path;
		if (typeof params !== 'object') params = {};
		if (typeof body !== 'object') body = {};
		
		const queryParams = Object.entries(params).filter(e => {
			const value = e[1];
			return (typeof value !== 'undefined' && value !== null);
		}).map(e => {
			let [name, value] = e;

			if (value instanceof Date) {
				value = value.getTime();
			} else if (Array.isArray(value)) {
				value = JSON.stringify(value);
			}
			
			return name + '=' + value;
		}).join('&');

		let response;
		try {
			let token;
			if (!opts.public) {
				token = auth.getValidStoredTokens(false);
				if (!token) throw new Error('Expired token');
			}
	
			let bodyParams;
			const isFormData = (body instanceof FormData);
			if (isFormData) {
				bodyParams = body;
			} else {
				const filteredBodyParams = this._filterRequest(body);
				bodyParams = (Object.keys(filteredBodyParams).length > 0) ? JSON.stringify(filteredBodyParams) : null;
			}
			
			let headerParams = {
				'Authorization': token && 'Bearer ' + token.accessToken,
			};
			if (!isFormData) {
				headerParams['Content-Type'] = 'application/json';
			}
			
			response = await fetch(Config.api.host + path + '?' + queryParams, {
				method: method || 'GET',
				headers: headerParams,
				body: bodyParams,
			});
			if (response.status !== 200) throw new Error("Got HTTP code " + response.status);
			
			const result = await response.json();
			if (!result.data) throw new Error('No data');

			return result;
		} catch (err) {
			let responseBody;
			try {
				responseBody = await response.json();
			} catch (err) {
				responseBody = null;
			}
			
			if (!response) {
				// Network error
				return {
					error: {
						message: 'Network error',
					}
				};
			} else if (!opts.public && (
				err.message.includes('token') || (response.status === 401)
			)) {
				if (!opts.repeat) {
					// Remove token & go home, you're drunk
					this.forceLogout();
					return {
						error: {
							message: 'Invalid token in second try',
						},
					};
				}

				// Refresh the current token
				const newTokens = await this.refreshToken();
				if (newTokens.error) {
					this.forceLogout();
					return {
						error: {
							message: 'Could not refresh token',
						},
					};
				}

				// Store new tokens
				auth.storeTokens(newTokens);

				// Repeat this function call
				return this._request(method, path, params, body, { ...opts, repeat: false });
			} else if (response.status === 403) {
				// Forbidden action
				return {
					error: {
						message: 'Forbidden action',
					}
				};
			} else if (responseBody && responseBody.error) {
				return {
					error: responseBody.error,
				};
			}

			return {
				error: {
					message: 'Unknown error',
				},
			};
		}
	}

	static _getFormData(data) {
		const formData = new FormData();
		for (const [k, v] of Object.entries(data)) {
			formData.append(k, v);
		}
		return formData;
	}
	
	static async _getData(path, params) {
		return this._request('GET', path, params);
	}
	
	static async _putData(path, body) {
		return this._request('PUT', path, {}, body);
	}
	
	static async _putMultiPartData(path, data) {
		const formData = this._getFormData(data);
		return this._putData(path, formData);
	}
	
	static async _postData(path, body) {
		return this._request('POST', path, {}, body);
	}
	
	static async _postMultiPartData(path, data) {
		const formData = this._getFormData(data);
		return this._postData(path, formData);
	}
	
	static async _deleteData(path, body) {
		return this._request('DELETE', path, {}, body);
	}

	static async getVisits(startTime, endTime) {
		return this._getData('/admin/metrics/getVisits', {startTime, endTime});
	}
	
	static async getChannels(startTime, endTime) {
		return this._getData('/admin/metrics/getChannels', {startTime, endTime});
	}
	
	static async getVisitsByReferral(startTime, endTime) {
		return this._getData('/admin/metrics/getVisitsByReferral', {startTime, endTime});
	}
	
	static async getVisitsByCountry(startTime, endTime) {
		return this._getData('/admin/metrics/getVisitsByCountry', {startTime, endTime});
	}
	
	static async getNewUsersCount(startTime, endTime) {
		return this._getData('/admin/metrics/getNewUsersCount', {startTime, endTime});
	}
	
	static async getRecurrentUsersCount(startTime, endTime) {
		return this._getData('/admin/metrics/getRecurrentUsersCount', {startTime, endTime});
	}
	
	static async getAvgSessionTime(startTime, endTime) {
		return this._getData('/admin/metrics/getAvgSessionTime', {startTime, endTime});
	}
	
	static async getBounceRate(startTime, endTime) {
		return this._getData('/admin/metrics/getBounceRate', {startTime, endTime});
	}
	
	static async getVisitsByPage(startTime, endTime) {
		return this._getData('/admin/metrics/getVisitsByPage', {startTime, endTime});
	}
	
	static async getPercentageDurationByPage(startTime, endTime) {
		return this._getData('/admin/metrics/getPercentageDurationByPage', {startTime, endTime});
	}
	
	static async getConversions(startTime, endTime) {
		return this._getData('/admin/metrics/getConversions', {startTime, endTime});
	}
	
	static async getRegistrations(startTime, endTime) {
		return this._getData('/admin/metrics/getRegistrations', {startTime, endTime});
	}
	
	static async refreshToken() {
		const data = auth.getStoredTokens();
		if (!data) throw new Error('Not tokens');
		
		return this._request('POST', '/admin/user/login', {}, {
			refreshToken: data.refreshToken,
			grantType: 'refresh_token',
		}, {
			public: true
		});
	}
	
	static async login(data) {
		return this._request('POST', '/admin/user/login', {}, {
			...data,
			grantType: 'access_token',
		}, {
			public: true
		});
	}
	
	static async forgotPassword(data) {
		return this._request('POST', '/admin/user/forgotPassword', {}, data, {
			public: true
		});
	}
	
	static async createPassword(data) {
		return this._request('POST', '/admin/user/createPassword', {}, data, {
			public: true
		});
	}
}

export default ServerAPI;
