import {
	LOGIN_AUTH_ERROR, LOGIN_ERROR, LOGIN_REQUEST, LOGIN_SUCCESS, LOGOUT,
} from './types';

import { auth } from '../../utils';
import ServerAPI from '../../services/server-api';

export const loginAuthError = error => ({
	type: LOGIN_AUTH_ERROR,
	error,
});

export const loginError = error => ({
	type: LOGIN_ERROR,
	error,
});

export const loginRequest = {
	type: LOGIN_REQUEST,
};

export const loginSuccess = data => ({
	type: LOGIN_SUCCESS,
	data,
});

export const logoutAction = {
	type: LOGOUT,
};

export const login = data => async (dispatch) => {
	dispatch(loginRequest);
	try {
		const result = await ServerAPI.login(data);
		if (result.error) {
			dispatch(loginError('Invalid credentials'));
		} else {
			// Store tokens
			await auth.storeTokens(result.data);

			dispatch(loginSuccess(result.data));
		}
	} catch (e) {
		dispatch(loginError(e.message));
	}
};

export const logout = () => async (dispatch) => {
	try {
		// Store tokens
		await auth.removeTokens();

		dispatch(logoutAction);
	} catch (e) {
		dispatch(loginError(e.message));
	}
};
