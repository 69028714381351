import React, { Component } from 'react';
import PropTypes from 'prop-types';

import deepEqual from 'deep-equal';
import { Chart } from "react-google-charts";
import { withStyles } from '@material-ui/core/styles';
import {
	Box, Card, CardContent, CircularProgress, Divider,
	Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core';

import DateRangePicker from '../../../components/inputs/date-range-picker';
import ServerAPI from '../../../services/server-api';

const styles = theme => ({
	root: {
		textAlign: "center"
	},
	title: {
		fontSize: 14,
		width: "100%",
	},
	button: {
		margin: theme.spacing(1)
	},
	cardContent: {
		padding: theme.spacing(0),
	},
	divider: {
		margin: "20px 0px",
		height: "1px",
	},
	flex: {
		display: "flex",
		[theme.breakpoints.down('sm')]: {
			flexDirection: "column",
		},
	},
	fullTable: {
		width: "100%",
		display: "block",
	},
	halfWidth: {
		width: "50%",
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			marginBottom: "10px",
		},
	},
	halfTable: {
		width: "40%",
		[theme.breakpoints.down('sm')]: {
			width: "100%",
			marginBottom: "10px",
		},
	},
	datePicker: {
		paddingBottom: "0px",
	},
	favicon: {
		maxWidth: '20px',
		marginRight: "10px",
		verticalAlign: "middle",
	}
});

class PageviewsPage extends Component {
	state = {
		isLoading: true,
		startTime: new Date((new Date()).setMonth((new Date()).getMonth() - 1)),
		endTime: new Date(),
		viewsChartData: [],
		channelsChartData: [],
		channelsTableData: [],
		viewsByCountryMapData: [],
		referralsTableData: [],
	};

	updateViewsData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getVisits(startTime, endTime);
		if (!results.data) return [];
		
		let viewsChartData = [];
		for (const e of results.data.events) {
			viewsChartData.push([
				new Date(e.createdAt),
				e.visits
			]);
		}
		
		// Descendig order
		viewsChartData.sort((a, b) => a[0] - b[0]);
		
		return viewsChartData;
	};
	
	updateChannelsData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getChannels(startTime, endTime);
		if (!results.data) {
			return {
				channelsChartData: [],
				channelsTableData: [],
			};
		}
		
		let totalViews = 0;
		let channelsTableData = [];
		for (const [k, v] of Object.entries(results.data)) {
			channelsTableData.push([
				k,
				v.total
			]);
			totalViews += v.total;
		}
		
		// Descendig order
		channelsTableData.sort((a, b) => (b[1] - a[1]) || a[0].localeCompare(b[0]));
		
		let channelsChartData = [];
		for (const [k, v] of Object.entries(results.data)) {
			channelsChartData.push([
				k,
				v.total / totalViews,
			]);
		}
		
		// Descendig order
		channelsChartData.sort((a, b) => (b[1] - a[1]) || a[0].localeCompare(b[0]));
		
		return {
			channelsChartData,
			channelsTableData,
		};
	};

	updateViewsByCountryData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getVisitsByCountry(startTime, endTime);
		if (!results.data) return [];
		
		const countries = {};
		for (const e of results.data.events) {
			for (const c of e.countries) {
				if (!countries[c.country]) countries[c.country] = 0;
				countries[c.country] += c.visits;
			}
		}
		const viewsByCountryMapData = Object.entries(countries);
		
		// Descendig order
		viewsByCountryMapData.sort((a, b) => (b[1] - a[1]) || a[0].localeCompare(b[0]));
		
		return viewsByCountryMapData;
	};

	updateReferralsData = async (newState = {}) => {
		const { startTime, endTime } = { ...this.state, ...newState };
		const results = await ServerAPI.getVisitsByReferral(startTime, endTime);
		if (!results.data) return [];
		
		const domains = {};
		for (const e of results.data.events) {
			for (const d of e.domains) {
				if (!domains[d.domain]) domains[d.domain] = 0;
				domains[d.domain] += d.visits;
			}
		}
		const referralsTableData = Object.entries(domains);
		
		// Descendig order
		referralsTableData.sort((a, b) => b[1] - a[1] || a[0].localeCompare(b[0]));
		
		return referralsTableData;
	};

	updateData = async (newState = {}) => {
		const viewsChartData = await this.updateViewsData(newState);
		const { channelsChartData, channelsTableData } = await this.updateChannelsData(newState);
		const referralsTableData = await this.updateReferralsData(newState);
		const viewsByCountryMapData = await this.updateViewsByCountryData(newState);
		
		// Check if data changed
		if (deepEqual(this.state.viewsChartData, viewsChartData)
			&& deepEqual(this.state.channelsChartData, channelsChartData)
			&& deepEqual(this.state.channelsTableData, channelsTableData)
			&& deepEqual(this.state.viewsByCountryMapData, viewsByCountryMapData)
			&& deepEqual(this.state.referralsTableData, referralsTableData)) {
			if (!Object.keys(newState).length) return;
			return this.setState({
				...newState,
			});
		}
		
		this.setState({
			...newState,
			isLoading: false,
			viewsChartData,
			channelsChartData,
			channelsTableData,
			referralsTableData,
			viewsByCountryMapData,
		});
	};

	componentDidMount = async () => {
		// Trigger update
		this.updateData();

		this.interval = setInterval(() => {
			this.updateData();
		}, 5000);
	};
	
	componentWillUnmount = () => {
		clearInterval(this.interval);
	};

	setStartTime = (date) => {
		this.updateData({
			startTime: date
		});
	};
	
	setEndTime = (date) => {
		this.updateData({
			endTime: date
		});
	};

	channelToString = (channel) => {
		const strings = {
			'direct': 'Direct',
			'socialNetwork': 'Social Networks',
			'searches': 'Search Engines',
			'referral': 'Other sites',
			'ads': 'Ads',
		};
		return strings[channel] || channel;
	};

	render() {
		const { classes } = this.props;
		const {
			isLoading,
			startTime, endTime,
			viewsChartData,
			channelsChartData, channelsTableData,
			referralsTableData,
			viewsByCountryMapData,
		} = this.state;
		
		return (
			<Box className={classes.root}>
				{isLoading ? (
					<CircularProgress />
				) : (
					<Card variant="outlined">
						<CardContent className={classes.cardContent}>
							<DateRangePicker
								className={classes.datePicker}
								container={false}
								startDate={startTime}
								endDate={endTime}
								onChangeStart={this.setStartTime}
								onChangeEnd={this.setEndTime}
								/>
							<Divider className={classes.divider} />
							<Typography variant="h5" gutterBottom>
								Pageviews
							</Typography>
							<Chart
								width={'100%'}
								height={'100%'}
								chartType="AreaChart"
								loader={<div>Loading Chart</div>}
								data={[
									[{ type: 'date', label: 'Day' }, 'Pageviews'],
									...viewsChartData,
								]}
								options={{
									hAxis: {
										title: 'Dates',
									},
									vAxis: {
										title: 'Pageviews',
									},
									series: {
										//0: { curveType: 'function' },
									},
								}}
								/>
							<Divider className={classes.divider} />
							<Typography variant="h5" gutterBottom>
								Channels
							</Typography>
							<div className={classes.flex}>
								<Chart
									width={'60%'}
									height={'100%'}
									chartType="PieChart"
									loader={<div>Loading Chart</div>}
									data={[
										['Channel', 'Views'],
										...channelsChartData,
									]}
									options={{
										title: 'Top Channels',
									}}
									/>
								<TableContainer className={classes.halfTable}>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>Channels</TableCell>
												<TableCell>Pageviews</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{channelsTableData.map((row, i) => (
												<TableRow key={i}>
													<TableCell>{this.channelToString(row[0])}</TableCell>
													<TableCell>{row[1]}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</div>
							<Divider className={classes.divider} />
							<div className={classes.flex}>
								<div className={classes.halfWidth}>
									<Typography variant="h5" gutterBottom>
										Views by country
									</Typography>
									<Chart
										width={'100%'}
										chartType="GeoChart"
										data={[
											['Country', 'Views'],
											...viewsByCountryMapData,
										]}
										mapsApiKey="YOUR_KEY_HERE"
										/>
								</div>
								<div className={classes.halfWidth}>
									<Typography variant="h5" gutterBottom>
										Referrals
									</Typography>
									<TableContainer>
										<Table aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>Referral</TableCell>
													<TableCell>Pageviews</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{referralsTableData.map((row) => (
													<TableRow key={row[0]}>
														<TableCell>
															<img
																className={classes.favicon}
																src={"https://" + row[0] + "/favicon.ico"}
																onError={(e) => {e.target.style.display = 'none';}}
																alt={row[0]}
																/>
															{row[0]}
														</TableCell>
														<TableCell>{row[1]}</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</div>
							</div>
						</CardContent>
					</Card>
				)}
			</Box>
		);
	}
}

PageviewsPage.propTypes = {
	classes: PropTypes.object.isRequired,
};

PageviewsPage.defaultProps = {
	classes: {},
};

export default withStyles(styles)(PageviewsPage);
