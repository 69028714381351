const NAME = 'PREFERENCES';
const Storage = window.localStorage;

const get = (str) => {
	try {
		const content = Storage.getItem(NAME);
		if (!content) return null;
		const obj = JSON.parse(content);
		return (str) ? (obj[str] || null) : obj;
	} catch (error) {
		return null;
	}
};

const store = (newPreferences) => {
	try {
		const current = get() || {};

		// Merge only first level of object
		Object.entries(newPreferences).forEach(([k, v]) => {
			if (current[k] && typeof current[k] === 'object' && !Array.isArray(current[k])) {
				current[k] = {
					...current[k],
					...v,
				};
			} else {
				current[k] = v;
			}
		});

		Storage.setItem(NAME, JSON.stringify(current));

		return true;
	} catch (error) {
		return false;
	}
};

const preferenceUtils = {
	get: get,
	store,
};

export default preferenceUtils;
