import React from 'react';
import { Route } from 'react-router-dom';

import AbstractRoute from './abstract-route';

class PrivateRoute extends React.Component {
	render() {
		const { component: Component, ...rest } = this.props;
		
		return (
			<Route
				{...rest}
				render={(routeProps) => <AbstractRoute
					{...routeProps}
					component={Component}
					isPrivate={true}
					redirectTo={"/login"}
					/>}
				/>
		);
	}
}

export default PrivateRoute;
