module.exports = {
	environment: process.env.REACT_APP_ENVIRONMENT || 'development',
	isDev: function () {
		return this.environment !== 'production';
	},
	basePath: '/',
	api: {
		host: process.env.REACT_APP_ANALYTICS_API_HOST || 'http://localhost:8044',
	},
};
