class PathLocationManager {
	constructor() {
		this.pastLocations = [];
		this.key = "appLocationHistory";
	}

	push(location) {
		this.pastLocations.push(location);
	}

	pop() {
		this.pastLocations.pop();
	}

	length() {
		return this.pastLocations.length;
	}

	setLocation(index, location) {
		this.pastLocations[index] = location;
	}

	getLocation(index) {
		return this.pastLocations[index];
	}

	setLocations(locations) {
		this.pastLocations = locations;
	}
}

const pastLocations = new PathLocationManager();

function updatePastLocations(location, action) {
	switch (action) {
		case "PUSH": {
			// first location when app loads and when pushing onto history
			pastLocations.push(location);
			break;
		}
		case "REPLACE": {
			// only when using history.replace
			pastLocations.setLocation(pastLocations.length() - 1, location);
			break;
		}
		case "POP": {
			// happens when using the back button, or forward button
			pastLocations.pop();
			// location according to pastLocations
			const appLocation = pastLocations.getLocation(pastLocations.length() - 1);
				if (!(appLocation && appLocation.key === location.key)) {
				// If the current location doesn't match what the app thinks is the current location,
				// blow up the app history.
				pastLocations.setLocations([location]);
			}
			break;
		}
		default: {
			
		}
	}
}

let history;

function initHistoryUtils(localHistory) {
	history = localHistory;
	history.listen(updatePastLocations);
}

function isPreviousLocationWithinApp() {
	return pastLocations.length() > 1;
}

function goBackOrPush(...args) {
	if (!history) return;
	
	if (isPreviousLocationWithinApp()) {
		history.goBack();
	} else {
		// Replace current page
		history.replace(...args);
	}
}

const historyUtils = {
	initHistoryUtils,
	goBackOrPush
};

export default historyUtils;
